import React from "react";
import { RiStarSFill } from "@remixicon/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function StepSeven() {
    const { t } = useTranslation();
  return (
    <div>
    <img className="table mx-auto" src="assets/img/odeme-sonuc.png" />

 


      <h2 className="text-color7 text-hmdx2 font-light mb-1 text-center mt-8"
            dangerouslySetInnerHTML={{ __html: t('odeme_basarili') }}
          />

 
    <p className="text-white font-light text-psm mb-5 text-center">
    {t('odeme_basarili_text')}
    </p>
    <div>
      <Link className="block bg-color2 px-2 py-5 text-center mb-2 text-hsm">
        <div className="stars-box">
          <RiStarSFill className="inline-block" />
          <RiStarSFill className="inline-block" />
          <RiStarSFill className="inline-block" />
          <RiStarSFill className="inline-block" />
          <RiStarSFill className="inline-block text-color7" />

          <span className="inline-block pl-4">
            {t('degerlendir')}
          </span>
        </div>
      </Link>
    </div>
    <div>
    <Link to="/" className="block bg-color2 px-2 py-5 text-center mb-4 text-hsm">
    {t('anasayfa_don')}
      </Link>
    </div>
  </div>
  );
}
