import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { usePurchase } from "../../contexts/PurchaseContext";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from "react-i18next";
import {
  RiCloseLargeFill,
  RiMoneyEuroCircleLine,
  RiMailFill,
  RiUser3Fill,
} from "@remixicon/react";
import { Alert } from "reactstrap";
import DOMPurify from "dompurify";
import axios from "axios";
import { inject, observer } from "mobx-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SmsCheckbox } from "../../component/order/SmsCheckbox";
import { CouponCode } from "../../component/order/CouponCode";
import { UserForm } from "../../component/order/UserForm";
import { TotalPrice } from "../../component/order/TotalPrice";
import { TermsAndConditions } from "../../component/order/TermsAndConditions";
import { ErrorAlert } from "../../component/order/ErrorAlert";
import { ContractPopup } from "../../component/order/ContractPopup";
import { formatDate } from "../../helpers/utils";
import { OrderSummary } from "../../component/order/OrderSummary";
import { LoadingSpinner } from "../../component/spinner/LoadingSpinner";
import { InvoiceForm } from "../../component/order/InvoiceForm";
import { PaymentForm } from "../../component/order/PaymentForm";
import { StripePaymentForm } from "../../component/order/StripePaymentForm";

const StepSix = inject(
  "AuthStore",
  "BasketStore"
)(
  observer(({ handlePrev, handleNext, AuthStore, BasketStore }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [errorVal, setError] = useState("");
    const [errorValCard, setErrorCard] = useState("");
    const [clientSecret, setClientSecret] = useState('');
    const [urlCallBack, setUrlCallBack] = useState('');

    
    const [loading, setLoading] = useState(false);
    const [countryS, setcountryS] = useState([]);
    const {
      purchaseData,
      addToCart,
      cartItems,
      updateTotalPrice,
      removeFromCart,
      updatePurchaseData,
    } = usePurchase();
    const [isCheckedContract, setIsCheckedCheckedContract] = useState(
      false,
      null
    );
    const stripePromise = loadStripe('pk_live_51PboGxGYRLAu51uu0XrtPbq6618Sw4e8rDb5KLobtupDy6IYQfDSxtl6QWmR7sem7Q5QNeko3ATX13VVGkxnWm3200xqo5P2ss');

    const BASE_URL = process.env.REACT_APP_API_URL;
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedIncoive, setIsCheckedIncoive] = useState(false);
    const [isCheckedSms, setIsCheckedSms] = useState(false);
    const [isSubmitForm, setIsSubmitForm] = useState(false);
    const [isMessage, setisMessage] = useState(false);
    const [invoiceForm, setInvoiceForm] = useState(false);
    const [paymentTypeId, setPaymentTypeId] = useState(3); // 1 for fzy, 2 for Stripe

    const [pageContent, setPageContent] = useState(null);
    const [emailForm, setemailForm] = useState(null);
    const [nameForm, setnameForm] = useState(null);
    const [surnameForm, setsurnameForm] = useState(null);
    const [paymentOpen, setpaymentOpen] = useState(false);
    const [payment3D, setpayment3D] = useState(null);
    const [paymentLoading, setpaymentLoading] = useState(false);
    const [totalPriceBuy, settotalPriceBuy] = useState(() =>
      roundToTwo(cartItems.reduce((total, item) => total + item.totalPrice, 0))
    );
    const smsPrice = 0.99;
    const formattedStartDate = formatDate(purchaseData.startDate);
    const formattedinishDate2 = formatDate(purchaseData.finishDate);

    useEffect(() => {
      if (cartItems.length === 0) {
        handlePrev();
      }
    }, [cartItems, handlePrev]);
    const [formData, setFormData] = useState({
      currency: 2,
      totalPrice: totalPriceBuy,
      discountPrice: 0,
      couponCode: null,
      userId: 0,
      email: "",
      isSms: false,
      phone: "",
      name: "",
      lastName: "",
      IsAddInvoice: true,
      Invoice: {
        Name: "",
        SurName: "",
        Email: "",
        CountryID: null,
        CountryName: "",
        TownName: "",
        CityName: "",
        CompanyName: "",
        TaxNo: "",
        Postcode: "",
        Address: "",
        Phone: "",
        TaxTypeID: 2,
      },
      Vignettes: cartItems,
    });

    const [formDataPayment, setformDataPayment] = useState({
      paymentTypeID: 1,
      holderName: "",
      number: "",
      number2: "",
      expireYear: "",
      expireMonth: "",
      expireDate: "",
      cvc: "",
      totalPrice: 0,
    });
    const ClosePaymentHandle = () => {
      setpaymentOpen(false);
      setLoading(false);
    };

    const NextButton = async () => {
      setpaymentLoading(false);
      if (isChecked) {
        if (paymentTypeId === 2) { // 2 is for Stripe
          try {
            const headers = AuthStore.isLoggedIn
              ? { Authorization: `Bearer ${AuthStore.appState.token}` }
              : {};
            let formDataToSend = { ...formData, paymentTypeID: paymentTypeId };
    
            if (!AuthStore.isLoggedIn) {
              if (emailForm && nameForm && surnameForm) {
                formDataToSend = {
                  ...formDataToSend,
                  email: emailForm,
                  firstName: nameForm,
                  lastName: surnameForm,
                };
                setFormData(formDataToSend);
              } else {
                setError("Lütfen tüm gerekli bilgileri doldurun.");
                return;
              }
            } else {
              const {
                Id: userId,
                FirstName: firstName,
                LastName: lastName,
              } = AuthStore.appState.user;
              if (userId) {
                formDataToSend = {
                  ...formData,
                  userId,
                  firstName,
                  lastName,
                };
                setFormData(formDataToSend);
              } else {
                setError("Eksik Bilgiler Mevcut");
                setLoading(false);
                setpaymentLoading(false);
                return;
              }
            }
    
            axios.defaults.withCredentials = true;
            const response = await axios.post(`${BASE_URL}/v1/StripePayment/CreatePaymentIntent`, formDataToSend, { headers });
            if (response.data.Success) {
              const clientSecret = response.data.Data.clientSecret; // Adjust according to your API response structure
              const callbackUrl = response.data.Data.urlCallBack; // Adjust according to your API response structure

              setClientSecret(clientSecret);
              setUrlCallBack(callbackUrl);
              setpaymentOpen(true);
              setisMessage(false);
            } else {
              setError(response.data.message);
            }
            
          } catch (error) {
            console.error('Error creating payment intent:', error);
            setError('Ödeme işlemi oluşturulurken bir hata oluştu.');
          }
        } 
        else if(paymentTypeId === 3) {
          // fzy not 3d payment process
          setpaymentOpen(true);
          setisMessage(false);
        }
        else {
          // fzy payment process
          setpaymentOpen(true);
          setisMessage(false);
        }
      } else {
        setisMessage(true);
      }
    };
    
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const keys = name.split(".");
      setFormData((prev) =>
        keys.length > 1
          ? { ...prev, Invoice: { ...prev.Invoice, [keys[1]]: value } }
          : { ...prev, [name]: value }
      );
    };
    const handlePaymentChange = (event) => {
      const { name, value } = event.target;




      if (name === "expireDate") {




        let numericValue = value.replace(/[^0-9]/g, "").slice(0, 6);
    

        if (numericValue.length >= 2) {
          const expireMonth = numericValue.slice(0, 2);
          let expireYear = numericValue.slice(2);

          if (expireYear.length > 4) {
            expireYear = expireYear.slice(-2);
          }

          const formattedExpireDate = expireYear
            ? `${expireMonth} / ${expireYear}`
            : expireMonth;

          setformDataPayment((prev) => ({
            ...prev,
            expireMonth: expireMonth,
            expireYear: expireYear,
            expireDate: formattedExpireDate,
          }));
        } else {
          setformDataPayment((prev) => ({
            ...prev,
            expireDate: numericValue,
            expireMonth: "",
            expireYear: "",
          }));
        }
      } else {

        const numericValue =
          name !== "holderName" ? value.replace(/[^0-9]/g, "") : value;


        setformDataPayment((prev) =>
          name.includes(".")
            ? {
                ...prev,
                Payment: {
                  ...prev.Payment,
                  [name.split(".")[1]]: numericValue,
                },
              }
            : { ...prev, [name]: numericValue }
        );
      }

      if (name === "number") {
           
        const numericValue = value.replace(/[^0-9]/g, "");
        const formattedValue = numericValue.match(/.{1,4}/g).join(' ');

        setformDataPayment((prev) => ({
            ...prev,
            number2: formattedValue,
            number: numericValue,
        }));

        console.log(formDataPayment);
      
      }
    };

    const handleCheckboxChange = (index, Id) => {
      setIsCheckedCheckedContract(index);
      axios
        .get(`${BASE_URL}/v1/Page/Get`, {
          params: { DataId: Id, LanguageId: 1 },
        })
        .then((response) =>
          setPageContent({
            ...response.data,
            PageText: DOMPurify.sanitize(response.data.PageText),
          })
        )
        .catch((error) => console.error("Sayfa içeriği yüklenemedi!", error));
    };

    const handleSendData = async () => {
      try {
        const headers = AuthStore.isLoggedIn
          ? { Authorization: `Bearer ${AuthStore.appState.token}` }
          : {};
        axios.defaults.withCredentials = true;
        const response = await axios.post(
          `${BASE_URL}/v1/Order/Create`,
          formData,
          { headers }
        );

        if (response.data.Success) {
          const { Id: orderId, userId, sessionId } = response.data.Data;
          formDataPayment.orderId = orderId;
          formDataPayment.userId = userId;
          formDataPayment.sessionId = sessionId;

          if (paymentTypeId === 1 || paymentTypeId === 3) { // fzy payment process
            const paymentResponse = await axios.post(
              `${BASE_URL}/v1/Order/Payment`,
              formDataPayment
            );
            
            if (paymentResponse.data.Success) {
              if (paymentResponse.data.Data.ReturnType === 'Html') {
                BasketStore.saveBasket(formData.Vignettes);
                BasketStore.saveOrder(formData);
                setpayment3D(paymentResponse.data.Data.Form3d);
              } else if (paymentResponse.data.Data.ReturnType === 'RedirectToUrl') {
                window.location.href = paymentResponse.data.Data.Form3d;
              }
              else if (paymentResponse.data.Data.PaymentId && paymentResponse.data.Data.IsPayment) {

                const paymentId = paymentResponse.data.Data.PaymentId;
                const orderId = paymentResponse.data.Data.OrderId;
                const status = 2; // status değerini alıyoruz

              
                const newUrl = `${window.location.origin}${window.location.pathname}?successtype=${status}&paymentid=${paymentId}&orderid=${orderId}`;
                console.log('Redirecting to:', newUrl); // URL'yi konsola yazdırıyoruz

                
                window.location.href = newUrl;
            } 
              else {
                setError("error_redirect_type");
              }
            }
          }
        } else {
          setTimeout(() => setpaymentLoading(false), 1000);
        }
      } catch (error) {
        setTimeout(() => {
          setError(error.response.data.Message);
          setpaymentOpen(false);
          setLoading(false);
        }, 1000);
      }
      setIsSubmitForm(false);
    };

    useEffect(() => {
      if (isSubmitForm) handleSendData();
    }, [isSubmitForm]);

    const clearQueryParams = () =>
      history.replace({ pathname: location.pathname });

    useEffect(() => {
      axios
        .get(`${BASE_URL}/v1/LicenseCountry/GetList?LanguageId=1`)
        .then(
          (response) => response.data.Success && setcountryS(response.data.Data)
        )
        .catch((error) => console.error("Veri Gönderilemedi", error));
    }, []);

   
    const PaymentCheckHandle = () => {

      const PaymentControl =
        formDataPayment.number &&
        formDataPayment.expireDate &&
        formDataPayment.cvc &&
        formDataPayment.holderName;

        
      if (PaymentControl) {

        let exDate = formDataPayment.expireDate

        if(exDate.length < 9){
          setErrorCard("Tarihi Tam girmelisiniz. Örn: 06 / 2028")
        }else{
          setErrorCard("");
          setpaymentLoading(true);
          if (!AuthStore.isLoggedIn) {
            if (emailForm && nameForm && surnameForm) {
              setFormData((prev) => ({
                ...prev,
                email: emailForm,
                firstName: nameForm,
                lastName: surnameForm,
              }));
              setIsSubmitForm(true);
            }
          } else {
            const {
              Id: userId,
              FirstName: firstName,
              LastName: lastName,
            } = AuthStore.appState.user;
            if (userId) {
              setFormData((prev) => ({ ...prev, userId, firstName, lastName }));
              setIsSubmitForm(true);
            } else {
              setTimeout(() => {
                setError("Eksik Bilgiler Mevcut");
                setLoading(false);
                setpaymentLoading(false);
              }, 1000);
            }
          }
        }
      
       
    
      }
    };
    const handlecouponCodePlateClick = (couponCode) => {
      updatePurchaseData("couponCode", couponCode);
    };

    const handleSmsChange = () => {
      setIsCheckedSms(!isCheckedSms);
      if (!isCheckedSms) {
        settotalPriceBuy((prevPrice) => roundToTwo(prevPrice + smsPrice));
      } else {
        settotalPriceBuy((prevPrice) => roundToTwo(prevPrice - smsPrice));
      }
    };

    const handleSMSClick = (phone) => {
      setFormData((prev) => ({ ...prev, Phone: phone }));
    };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const successType = queryParams.get("successtype");
    const paymentId = queryParams.get("paymentid");
    const orderId = queryParams.get("orderid");

    useEffect(() => {
      if (successType && paymentId && orderId) {
        const ValidData = { successType, paymentId, orderId };
        const headers = AuthStore.isLoggedIn
          ? { Authorization: `Bearer ${AuthStore.appState.token}` }
          : {};
        axios.defaults.withCredentials = true;

        axios
          .post(`${BASE_URL}/v1/Payment/PaymentValid`, ValidData, { headers })
          .then((response) => {
            if (response.data.Success) {
              if (!response.data.Data.PaymentSuccess) {
                clearQueryParams();
                setError("Hata : " + response.data.Data.ErrorMessage);
              } else {
                // clearQueryParams();
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'purchase',
                  transaction_id: response.data.Data.OrderCode, 
                  value: response.data.Data.Data.totalPrice,
                  currency: response.data.Data.Data.currencyName, 
                  items: response.data.Data.Data.Vignettes.map((item) => ({
                    item_name: item.name,
                    item_id: item.Id,
                    price: item.totalPrice,
                    quantity: 1, 
                  })),
                });


                setpaymentOpen(true);
                handleNext(); 
              }
              setpaymentLoading(false);
            } else {
              setTimeout(() => setpaymentLoading(false), 1000);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              setError(error.response.data.Message);
              setpaymentOpen(false);
              setLoading(false);
            }, 1000);
          });
        setIsSubmitForm(false);
      }
    }, [successType]);

    return (
      <div>
        {loading && <LoadingSpinner />}
        <h2 className="text-color7 text-hmdx2 font-light mb-1">
          {t("odeme_ozeti")}{" "}
        </h2>
        <p className="text-white font-light text-psm mb-5">
          {t("odeme_ozeti_text")}
        </p>
        <OrderSummary
          cartItems={cartItems}
          totalPrice={totalPriceBuy}
          handlePrev={handlePrev}
        />
        <InvoiceForm
          invoiceForm={invoiceForm}
          setInvoiceForm={setInvoiceForm}
          formData={formData}
          handleInputChange={handleInputChange}
          countryS={countryS}
          setIsCheckedIncoive={setIsCheckedIncoive}
          isCheckedIncoive={isCheckedIncoive}
        />
        <SmsCheckbox
          isCheckedSms={isCheckedSms}
          formDataPayment={formDataPayment}
          formData={formData}
          handleSMSClick={handleSMSClick}
          handleSmsChange={handleSmsChange}
          handleInputChange={handleInputChange}
        />
        <CouponCode
          handlecouponCodePlateClick={handlecouponCodePlateClick}
          purchaseData={purchaseData}
        />
        {!AuthStore.isLoggedIn && (
          <UserForm
            emailForm={emailForm}
            setEmailForm={setemailForm}
            nameForm={nameForm}
            setNameForm={setnameForm}
            surnameForm={surnameForm}
            setSurnameForm={setsurnameForm}
          />
        )}
        <TotalPrice totalPriceBuy={totalPriceBuy} />
        <TermsAndConditions
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          handleCheckboxChange={handleCheckboxChange}
        />
        {isMessage && <div className="message-i">{t("l_sozlesme")}</div>}
        {errorVal && <ErrorAlert errorVal={errorVal} />}
        <button
          onClick={NextButton}
          className="bg-orange py-5 rounded-md mt-4 text-white font-semibold text-hsm w-full"
        >
          {" "}
          {t("odeme_yap")}
        </button>
        {isCheckedContract && pageContent && (
          <ContractPopup
            pageContent={pageContent}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
          {paymentOpen && (paymentTypeId === 1 || paymentTypeId === 3) && (
          <PaymentForm
            paymentLoading={paymentLoading}
            formDataPayment={formDataPayment}
            handlePaymentChange={handlePaymentChange}
            PaymentCheckHandle={PaymentCheckHandle}
            ClosePaymentHandle={ClosePaymentHandle}
            payment3D={payment3D}
            totalPriceBuy={totalPriceBuy}
            emailForm={emailForm}
            errorValCard={errorValCard}
            nameForm={nameForm}
            formData={formData}
            surnameForm={surnameForm}
            isCheckedSms={isCheckedSms}
            Auth={AuthStore.isLoggedIn}
          />
        )}
        {paymentOpen && paymentTypeId === 2 && (
          <Elements options={{ clientSecret }} stripe={stripePromise}>
            <StripePaymentForm 
              paymentLoading={paymentLoading}
              ClosePaymentHandle={ClosePaymentHandle}
              clientSecret={clientSecret}    
                        totalPriceBuy={totalPriceBuy}  
                        urlCallBack = {urlCallBack}
              nameForm={nameForm}
              surnameForm={surnameForm}
              formData={formData}
              emailForm={emailForm}
              errorValCard={errorValCard}
              setErrorCard={setErrorCard}
              isCheckedSms={isCheckedSms}
              Auth={AuthStore.isLoggedIn}
            />
          </Elements>
        )}
      </div>
    );
  })
);

export default StepSix;
const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};
