import React, { useRef, useState, Fragment, useEffect } from "react";
import { defaultPurchaseData } from "../../contexts/ResetData";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { usePurchase } from "../../contexts/PurchaseContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CountrySelect from "../../component/select/CountrySelect";

function TextboxComponent({ handleTextboxChange, textboxValue }) {
  return (
    <input
      value={textboxValue}
      onChange={(e) => handleTextboxChange(e.target.value)}
      className="pt-7 pl-7 pb-2 w-full rounded-r-lg"
      placeholder="34 AA 1252"
    />
  );
}
function ListSelect({ countryS, setselectedCountry, t }) {
 
  return (
    <div>
      <Listbox
      >
        {({ open }) => (
          <>
            <div className="mb-2 text-left block font-medium text-psm text-white">
              {t('aracin_t_edildigi_ulke')}
            </div>
            <div className="relative mt-1">
              <CountrySelect countryS={countryS} setselectedCountry={setselectedCountry} />
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default function StepFour({ handlePrev, handleNext }) {
  const { t } = useTranslation();
  const [textboxValue, setTextboxValue] = useState("");

  const { purchaseData, addToCart,saveToCart, cartItems } = usePurchase();
  const { updatePurchaseData } = usePurchase();
 
  const [countryS, setcountryS] = useState([]);
  const [selectedCountry, setselectedCountry] = useState(null);

  const [selectedName, setselectedName] = useState(null);

  const [selectedFlag, setselectedFlag] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_URL;
 

 

  useEffect(() => {
    axios
      .get(`${BASE_URL}/v1/LicenseCountry/GetList?LanguageId=1`)
      .then((response) => {
        if (response.data.Success) {
          setcountryS(response.data.Data);
        }
      })
      .catch((error) => {
        console.error("Veri Gönderilemedi", error);
      });
  }, []);


  
  const resetPurchaseData = () => {
    Object.entries(defaultPurchaseData).forEach(([key, value]) => {
      updatePurchaseData(key, value);
    });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handlelisencePlateClick = (lisencePlate) => {
    // updatePurchaseData("lisencePlate", lisencePlate);
  };
  const handleTextboxChange = (e) => {
    setTextboxValue(e);
  };

  if (purchaseData.startDate == "") {
    handlePrev();
  }
  const NextButton = () => {
    console.log(selectedCountry)
    updatePurchaseData("lisenceCountry", selectedCountry.Id);
    updatePurchaseData("lisenceCountryName", selectedCountry.Name);
    updatePurchaseData("lisenceCountryImg", selectedCountry.DataCode);
    updatePurchaseData("lisencePlate", textboxValue);

    
    
    
  };
  useEffect(() => {
    if (
      purchaseData.lisencePlate !== "" &&
      purchaseData.lisenceCountryName !== ""
    ) {
      addToCart({
        ...purchaseData,
        id: Date.now(),
      });
  
      handleNext();
      resetPurchaseData();
    }
  }, [purchaseData]);
  return (
    <div>
      <h2 className="text-color7 text-hmdx2 font-light mb-1">
       {t('plakanızı_giriniz')}
      </h2>
      <p className="text-white font-light text-psm">
        {t('plakanızı_giriniz_text')}
     
      </p>
      <div className="select-lab mt-6">
      <ListSelect
          countryS={countryS}
          setselectedCountry={setselectedCountry}
          t={t}
        />
      </div>
      <div className="select-lab mt-8">
        <label className="mb-3 block font-medium text-psm text-white ">
        {t('plakaniz')}
        </label>

        <div className="plk">
          <div className="flex rounded-lg">
            <label className="plk-2">
              {purchaseData.lisenceCountryName
                ? purchaseData.lisenceCountryName.charAt(0).toUpperCase() +
                  purchaseData.lisenceCountryName.charAt(1).toUpperCase()
                : "TR"}
            </label>

            <TextboxComponent
        textboxValue={textboxValue}
        handleTextboxChange={handleTextboxChange}
      />
          </div>

          <button
            onClick={() => NextButton()}
            className="bg-orange py-5 rounded-md mt-7 text-white font-semibold text-hsm w-full"
          >
             {t('devam_et')}
          </button>
        </div>
      </div>
    </div>
  );
}
