import React, { useEffect } from "react";
import $ from "jquery";
import "select2";
import { t } from "i18next";


export default function CountrySelect({ countryS, setselectedCountry }) {
  useEffect(() => {
    // Select2'yi başlatıyoruz

    $("#country-select").select2({
      placeholder: t("ulke_seciniz"), // Placeholder
      allowClear: true, // Temizleme seçeneği
      templateResult: formatCountry, // Açılır menüde özel görünüm
      templateSelection: formatCountrySelection, // Seçili öğe görünümü
    });

    // Seçim değiştiğinde çağırılacak fonksiyon
    $("#country-select").on("change", function () {
      const selectedId = $(this).val();
      const selected = countryS.find((country) => country.Id === parseInt(selectedId));
      setselectedCountry(selected);
    });

    return () => {
      $("#country-select").select2("destroy"); // Bileşeni temizle
    };
  }, [countryS]);

  // Açılır menüdeki her öğeyi özel görünümle render ediyoruz
  function formatCountry(country) {
    if (!country.id) return country.text; // Placeholder için
    const flagUrl = $(country.element).data("flag");
    return $(
      `<div class="flex items-center h-[40px] pl-4 pr-4">
        <img src="${flagUrl}" class="w-5 h-5 rounded-full mr-2" />
        <span>${country.text}</span>
      </div>`
    );
  }

  // Seçili öğeyi özel görünümle render ediyoruz
  function formatCountrySelection(country) {
    if (!country.id) return country.text; // Placeholder için
    const flagUrl = $(country.element).data("flag");
    return $(
      `<div class="flex items-center h-[40px] pl-4 pr-4">

        <img src="${flagUrl}" class="w-5 h-5 rounded-full mr-2" />
        <span>${country.text}</span>
      </div>`
    );
  }

  return (
    <select id="country-select" className="w-full rounded-md border border-gray-300 p-2 text-sm">
      <option value="" disabled>
        Select a country
      </option>
      {countryS.map((country) => (
        <option key={country.Id} value={country.Id} data-flag={country.DataCode}>
          {country.Name}
        </option>
      ))}
    </select>
  );
}
